import {Flex} from "../../components/Container/Flex";
import {DeviceAPI} from "../../utils/API/DeviceAPI";
import React from 'react';
import Device from "../../utils/models/Device";
import TextField from "../../components/Controls/TextField";
import {useTranslation} from "react-i18next";
import Dialog from "../../components/Dialog";
import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import TextButton from "../../components/Controls/TextButton";
import {shortDateTimeFormat} from "../../utils/style/formats";
import dayjs from "dayjs";

const api = new DeviceAPI();

const DeviceForm = props => {
    const [device, setDevice] = React.useState(null);
    const [url, setUrl] = React.useState(null);
    const [isLoading, setLoading] = React.useState(false);
    const [t] = useTranslation();
    React.useEffect(() => {
        if(device) return;
        api.getDeviceByUrl().then(({url, device}) => {
            setUrl(url);
            setDevice(new Device(device));
        });
    }, [device]);
    
    if(!device || !url) return null;
    const canSave = device.name?.length > 3;
    
    return (
        <Dialog
            open
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>{url.name}</DialogTitle>
            <DialogContent>
                <Flex col gap={6}>
                    <TextField
                        label={t('deviceName')}
                        fullWidth
                        value={device.name}
                        disabled={isLoading || !url.deviceName}
                        onChange={v => setDevice(device.set('name', v))}
                    />
                    {!!url.operatingAddress && (
                        <TextField
                            label={t('operatingAddress')}
                            disabled={isLoading}
                            fullWidth
                            value={device.operatingAddress}
                            onChange={v => setDevice(device.set('operatingAddress', v))}
                        />
                    )}
                    {!!url.tempAddress && (
                        <TextField
                            label={t('tempAddress')}
                            helperText={`${t('availableUntil')}: ${device.tempAddressExpirationDate.format(shortDateTimeFormat)}`}
                            style={{marginBottom: 12}}
                            disabled={isLoading}
                            fullWidth
                            value={device.tempAddress}
                            onChange={v => setDevice(device.set('tempAddress', v))}
                        />
                    )}
                    {!!url.industry && (
                        <TextField
                            label={t('industry')}
                            disabled={isLoading}
                            fullWidth
                            value={device.industry}
                            onChange={v => setDevice(device.set('industry', v))}
                        />
                    )}
                    {!!url.extra && device.get('extraFields').map((f, i) => {
                        if(!url.extra[f.name]) return null;
                        return (
                            <TextField
                                label={f.name}
                                key={f.name}
                                disabled={isLoading}
                                fullWidth
                                helperText={!!f.value && !!f.exp && `${t('availableUntil')}: ${dayjs.unix(f.exp).format(shortDateTimeFormat)}`}
                                value={f.value}
                                style={!!f.value && !!f.exp ? {marginBottom: 12} : {}}
                                onChange={value => {
                                    const newFields = [...device.get('extraFields')];
                                    newFields[i] = {...f, value};
                                    setDevice(device.set('extraFields', newFields));
                                }}
                            />     
                        )
                    })}
                </Flex>
            </DialogContent>
            <DialogActions>
                <TextButton
                    color="primary"    
                    loading={isLoading}
                    disabled={!canSave}
                    onClick={() => {
                        setLoading(true);
                        api.saveDeviceByUrl(device).then(() => window.location.reload());
                    }}
                >
                    {t('save')}
                </TextButton>
            </DialogActions>
        </Dialog>
    );
    
};

export default DeviceForm;